import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/CategoryPageLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Teams App`}</h1>
    <p>{`The Teams App allows you to efficiently manage teams within CoreMedia Contact Center and Analytics Studio. This app offers a range of essential features to help streamline team operations and enhance communication. The key functionality is: `}</p>
    <ul>
      <li parentName="ul">{`Configuration of in queue position announcements: Configure announcements that notify callers of their position in the queue, and therefore improving the customer experience.`}</li>
    </ul>
    <h2>{`In queue position`}</h2>
    <p>{`Learn how to effectively communicate the current queue position to customers, ensuring they are informed about their waiting time and position in the queue.`}</p>
    <h3>{`What is it for`}</h3>
    <p>{`The “in-queue position” feature, configured in the Teams app of CoreMedia Contact Center and Analytics Studio, is designed to inform customers of their current place in the waiting line, providing transparency and helping to manage their expectations regarding wait times.`}</p>
    <h3>{`What can I do with it`}</h3>
    <p>{`With in-queue position feature we are able to:`}</p>
    <ul>
      <li parentName="ul">{`Configure announcements to inform customers about their position in the queue or estimated wait time or both.`}</li>
      <li parentName="ul">{`Set the interval for announcements.`}</li>
      <li parentName="ul">{`Limit the number of position announcements.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      